<!--
 * @Descripttion: 我的动态
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-14 17:46:18
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-19 15:21:18
-->
<template>
  <div class="my-tab">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="my-dynamic">
      <template v-for="{label, name, isUpdate, iconSvg = true, tabComponent, type = '', show = true} in tabList" :key="name">
        <el-tab-pane :label="label" :name="name" v-if="show">
          <template v-if="iconSvg" #label>
            <p :class="`${name}-icon`">
              <template v-if="name === 'vote'">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.993 14.57a.993.993 0 0 1-.993-.993v-8.55a.992.992 0 1 1 1.985 0v8.55a.993.993 0 0 1-.992.992zm-4.037 0a.956.956 0 0 1-.956-.956V6.662a.956.956 0 1 1 1.911 0v6.952a.956.956 0 0 1-.955.955zm-7.95 0a.993.993 0 0 1-.993-.994V8.371A.993.993 0 0 1 5 8.37v5.205a.993.993 0 0 1-.993.993zm4.038 0a.956.956 0 0 1-.956-.957V3.457a.956.956 0 1 1 1.912 0v10.158a.956.956 0 0 1-.956.955zM17 16H3a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2z" fill="#999"/></svg>
              </template>
              <template v-else-if="name === 'fixed_blog'">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="5" width="14" height="14" rx="7" stroke="#999" stroke-width="2"/><rect x="7" y="1" width="6" height="2" rx="1" fill="#999"/><rect x="11" y="8" width="5" height="2" rx="1" transform="rotate(90 11 8)" fill="#999"/><rect x="14" y="13" width="5" height="2" rx="1" transform="rotate(-180 14 13)" fill="#999"/><rect x="11" y="2" width="4" height="2" rx="1" transform="rotate(90 11 2)" fill="#999"/></svg>
              </template>
              <template v-else-if="name === 'draft'">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 11h.586L5 12.414A2 2 0 0 0 6.414 13h7.172A2 2 0 0 0 15 12.414L16.414 11H17v6H3v-6z" stroke="#999" stroke-width="2"/><path d="M5 12V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8" stroke="#999" stroke-width="2"/><path d="M8 6h4M8 9h4" stroke="#999" stroke-width="2" stroke-linecap="round"/></svg>
              </template>
              {{ label }}
            </p>
          </template>
          <Suspense v-if="isUpdate">
            <template #default>
              <!-- 动态组件 -->
              <component :is="tabComponent" :type="type"/>
            </template>
          </Suspense>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, computed, reactive, toRefs, markRaw, defineProps, nextTick } from 'vue'
import MyDynamicList from './MyDynamicList.vue'
import MyDynamicVoteList from './MyDynamicVoteList.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getCookies, setCookies } from '@/utils/auth'

const props = defineProps({
  userId: {
    type: String,
    default: ''
  }
})

const route = useRoute()
const store = useStore()
const state = reactive({
  userId: computed(() => props.userId),
  tabList: [
    {
      label: '全部动态',
      name: 'blog',
      type: '1',
      isUpdate: computed(() => state.activeName === 'blog'),
      // iconSvg: false,
      tabComponent: markRaw(MyDynamicList)
    },
    {
      label: '投票',
      name: 'vote',
      isUpdate: computed(() => state.activeName === 'vote'),
      tabComponent: markRaw(MyDynamicVoteList)
    },
    {
      label: '定时动态',
      name: 'fixed_blog',
      type: '2',
      isUpdate: computed(() => state.activeName === 'fixed_blog'),
      tabComponent: markRaw(MyDynamicList),
      show: computed(() => store.getters['userInfo/isVirtualUser'])
    },
    {
      label: '草稿箱',
      name: 'draft',
      type: '0',
      isUpdate: computed(() => state.activeName === 'draft'),
      tabComponent: markRaw(MyDynamicList)
    }
  ],
  activeName: route.query.active || 'blog'
})

const { activeName, tabList } = toRefs(state)

const handleClick = (value) => {
  setCookies('activeName', value.props.name)
  window.scrollTo(0, 0)
}

const activeList = ['blog', 'vote', 'fixed_blog', 'draft']
onMounted(() => {
  nextTick(() => {
    const active = getCookies('activeName')
    if (activeList.includes(active)) {
      state.activeName = active
    }
  })
})

onUnmounted(() => {
  setCookies('activeName', 'blog')
})

// watch(() => route.query.active, (newValue, oldValue) => {
//   console.log(newValue)
//   if (newValue) {
//     const tabList = state.tabList.map(item => item.name)
//     if (tabList.includes(newValue)) {
//       state.activeName = newValue
//     }
//   }
// })

</script>
<style lang="less" scoped>
  .my-dynamic{
    .el-tabs__header.is-top{
      border-radius: 6px;
      border: 1px solid #F1F1F1;
    }
    .el-tabs__item{
      .label-icon{
        svg{
          position: relative;
          top: 4px;
        }
      }
    }
    :deep(.el-tabs__nav.is-top){
      display: flex;
      align-items: center;
      height: 46px;
    }
  }
</style>

<!--
 * @Descripttion: 投票列表
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2021-12-14 17:46:18
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-05 10:36:53
-->
<template>
  <div class="my-tab">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="my-vote">
      <template v-for="{label, name, isUpdate, type = ''} in tabList" :key="name">
        <el-tab-pane :label="label" :name="name">
          <Suspense v-if="isUpdate">
            <template #default>
              <!-- 动态组件 -->
              <DynamicList :type="type" :userId="userId"/>
            </template>
          </Suspense>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script setup>
import { reactive, toRefs, computed, defineProps } from 'vue'
import DynamicList from './MyDynamicList.vue'
const props = defineProps({
  userId: {
    type: String,
    default: ''
  }
})

const state = reactive({
  userId: computed(() => props.userId),
  activeName: 'first',
  tabList: [
    {
      label: '我参与的',
      name: 'first',
      type: '91',
      isUpdate: computed(() => state.activeName === 'first')
    },
    {
      label: '我创建的',
      name: 'second',
      type: '92',
      isUpdate: computed(() => state.activeName === 'second')
    }
  ]
})

const { userId, activeName, tabList } = toRefs(state)

const handleClick = () => {
  window.scrollTo(0, 0)
}

</script>

<style lang="less" scoped>

.my-vote{
    .el-tabs__header.is-top{
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom: none !important;
    }
    .el-tabs__content{
      margin-top: 0px !important;
      .blog-tpl{
        li{
          &:first-child{
            .blog-message.all-border-radius{
              border-top-right-radius: 0px;
              border-top-left-radius: 0px;
            }
          }
        }
      }
    }
  }
</style>

<!--
 * @Descripttion: 用户中心
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-16 11:01:55
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-07 14:32:36
-->
<template>
  <div class="personal-center mfzj my-tab">
    <UserMessage :userId="String(id)" :isSelf="isSelf"></UserMessage>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <template v-for="{label, name, isUpdate, tabComponent,visible = true} in tabList" :key="name">
        <el-tab-pane :label="label" :name="name" v-if="visible">
          <template #label>
            <p :class="`${name}-icon`">
              <template v-if="name === 'blog'">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.651 2.49l-1.44 1.44A5.996 5.996 0 0 1 16 9c0 2.17-1.151 4.07-2.876 5.124l1.444 1.444A7.99 7.99 0 0 0 18 9a7.99 7.99 0 0 0-3.349-6.51zM2 9a7.99 7.99 0 0 1 3.348-6.51l1.44 1.44A5.996 5.996 0 0 0 4 9c0 2.17 1.151 4.07 2.876 5.123l-1.444 1.445A7.99 7.99 0 0 1 2 9z" fill="#999"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14 9a4.002 4.002 0 0 1-3.008 3.876c.005.04.008.082.008.124v4a1 1 0 0 1-2 0v-4c0-.042.003-.084.008-.124A4.002 4.002 0 0 1 10 5a4 4 0 0 1 4 4zm-4 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill="#999"/></svg>
              </template>
              <template v-else-if="name === 'post'">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.707 5.257a1 1 0 0 0 1.414 1.414l3.55-3.55a1 1 0 0 0-1.414-1.414l-3.55 3.55z" fill="#999"/><path d="M5 3h5.875v.008a1 1 0 1 1 0 1.985V5H5v10h10V7.94h.002a1 1 0 0 1 1.996 0H17V15a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" fill="#999"/><path d="M6 9a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1zm0 3a1 1 0 0 1 1-1h6a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1z" fill="#999"/></svg>
              </template>
              <template v-else>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.317 5.217C7.849 3.594 5.468 3.594 4 5.217.69 8.876 6.36 15.958 9.96 16c3.626.042 9.367-7.106 6.04-10.783-1.468-1.623-3.848-1.623-5.317 0L10 5.972l-.683-.755z" stroke="#999" stroke-width="2" stroke-linecap="round"/></svg>
              </template>
              {{ label }}
            </p>
          </template>
          <Suspense v-if="isUpdate">
            <template #default>
              <!-- 动态组件 -->
              <component :is="tabComponent" :userId="String(id)"/>
            </template>
          </Suspense>
        </el-tab-pane>
      </template>
    </el-tabs>
    <template v-if="isSelf && !isVirtualUser && store.state.userInfo.isWhiteList !== -1">
      <p class="personal-news" >
        <span class="medal-news" @click="getMedal">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.01 1.23a7.862 7.862 0 0 1 6.244 12.64v3.377a1.483 1.483 0 0 1-1.87 1.431l-.024-.006-3.433-.992a3.144 3.144 0 0 0-1.712-.009l-.033.01-3.433.99a1.482 1.482 0 0 1-1.893-1.399v-3.287A7.862 7.862 0 0 1 10.01 1.23zm5.076 13.867a7.837 7.837 0 0 1-3.222 1.638l2.82.815a.314.314 0 0 0 .402-.288v-2.165zm-10.063.075v2.075a.314.314 0 0 0 .388.306l.014-.003 2.78-.803a7.833 7.833 0 0 1-3.182-1.575zM10.01 2.399a6.694 6.694 0 0 0-6.694 6.694 6.694 6.694 0 1 0 6.694-6.694zM9.49 5.68a.629.629 0 0 1 1.129 0l.88 1.784 1.967.286c.34.049.576.36.534.699l-.002.014a.63.63 0 0 1-.183.36l-1.424 1.388.336 1.96a.629.629 0 0 1-.5.724l-.013.002a.629.629 0 0 1-.4-.063l-1.76-.926-1.76.926a.629.629 0 0 1-.842-.251l-.007-.013a.63.63 0 0 1-.063-.4l.336-1.96-1.424-1.387a.63.63 0 0 1-.022-.878l.011-.012a.629.629 0 0 1 .36-.183l1.968-.286.88-1.784zm.565 1.497l-.669 1.354-1.494.218 1.081 1.054-.255 1.489 1.337-.703 1.336.703-.255-1.49 1.082-1.053-1.495-.218-.668-1.354z" fill="#777"/></svg>
          勋章
        </span>
        <router-link :to="{ name: 'employees' }">
          <span>
            <svg width="20" height="20" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M192 128v768h640V128H192zm-32-64h704a32 32 0 0 1 32 32v832a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32zm160 448h384v64H320v-64zm0-192h192v64H320v-64zm0 384h384v64H320v-64z"/></svg>
            档案
          </span>
        </router-link>
      </p>
    </template>
    <MedalDialog ref="medalRef"></MedalDialog>
  </div>
</template>

<script setup>

import { reactive, toRefs, onMounted, watch, computed, markRaw } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import UserMessage from './component/UserMessage.vue'
import MyDynamic from './component/MyDynamic.vue'
import MyDynamicList from './component/MyDynamicList.vue'
import MyPost from './component/MyPost.vue'
import MyCollect from './component/MyCollect.vue'
import MedalDialog from './component/MedalDialog'

const store = useStore()
const route = useRoute()

onMounted(() => {
  state.id = route.query.id || store.state.userInfo.userInfos?.id
  // 勋章消息通知弹出弹窗
  if (route.query.medal_open) {
    getMedal()
  }
})

watch(
  () => route.query.id,
  (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      state.id = newValue
      state.activeName = 'blog'
      handleClick()
    } else {
      state.id = store.state.userInfo.userInfos?.id
    }
  }
)

const state = reactive({
  id: route.query.id || store.state.userInfo.userInfos?.id,
  activeName: 'blog',
  isSelf: computed(() => Number(store.state.userInfo.userInfos?.id) === Number(state.id)),
  tabList: [
    {
      label: '动态',
      name: 'blog',
      isUpdate: computed(() => state.activeName === 'blog'),
      tabComponent: markRaw(computed(() => state.isSelf ? MyDynamic : MyDynamicList))
    },
    {
      label: '帖子',
      name: 'post',
      isUpdate: computed(() => state.activeName === 'post'),
      tabComponent: markRaw(MyPost)
    },
    {
      label: '收藏',
      name: 'collect',
      isUpdate: computed(() => state.activeName === 'collect'),
      tabComponent: markRaw(MyCollect),
      visible: computed(() => state.isSelf)
    }
  ],
  medalRef: null,
  isVirtualUser: computed(() => store.getters['userInfo/isVirtualUser'])
})
const { id, activeName, tabList, isSelf, medalRef, isVirtualUser } = toRefs(state)

function handleClick () {
  // 重置页脚状态
  store.commit('setLoadMore', false)
  store.commit('setNoMore', false)
}

const getMedal = () => {
  state.medalRef.getMedal()
}

</script>

<style lang="less" scoped>
@import '../../../assets/less/myTab.less';
.my-tab {
  position: relative;
}
.personal-news {
  position: absolute;
  right: 30px;
  top: 225px;
}
.personal-news span {
  color: #777;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;
}
.personal-news span.medal-news{
  margin-right: 16px;
  cursor: pointer;
}
.personal-news span svg{
  margin-top: -5px;
  vertical-align: middle;
}
.personal-center {
  width: 791px;
  margin: 0 auto;
  :deep(.el-tabs) {
    width: 100%;
    #pane-third {
      background: #f6f9fa !important;
    }
    #pane-second {
      border-radius: 6px;
    }
    .el-tabs__header {
      background: hsl(0, 0%, 100%) !important;
      padding: 0 34px;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      border-left: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;
    }
    .el-tabs__content {
      margin-top: 8px;
    }
    &.my-post {
      .el-tabs__header {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom: none;
      }
      .el-tabs__content {
        margin-top: 0;
      }
      .posts {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }

    .blog-tpl > li {
      border-radius: 6px !important;
      // overflow: hidden !important;
      &:first-child {
        margin-top: 0;
      }
    }
    .el-empty {
      border: 1px solid #f1f1f1;
      border-radius: 6px;
    }
    .posts .el-empty {
      border: none;
      border-radius: 0;
    }
  }
}
</style>

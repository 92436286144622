<!--
 * @Descripttion: 我的帖子
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2021-12-14 17:46:18
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-04 18:04:44
-->
<template>
  <div class="my-tab">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="my-post">
      <template v-for="{label, name, isUpdate} in tabList" :key="name">
        <el-tab-pane :label="label" :name="name">
          <Suspense v-if="isUpdate">
            <template #default>
              <!-- 动态组件 -->
              <MyPostList  :type="name" :userId="userId"/>
            </template>
          </Suspense>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script setup>
import { reactive, toRefs, computed, defineProps } from 'vue'
import MyPostList from './MyPostList.vue'

const props = defineProps({
  userId: {
    type: String,
    default: ''
  }
})

const state = reactive({
  userId: computed(() => props.userId),
  activeName: 'publish',
  tabList: [
    {
      label: '发布的',
      name: 'publish',
      isUpdate: computed(() => state.activeName === 'publish')
    },
    {
      label: '回复的',
      name: 'record',
      isUpdate: computed(() => state.activeName === 'record')
    }
  ]
})

const { userId, activeName, tabList } = toRefs(state)

const handleClick = () => {
  window.scrollTo(0, 0)
}

</script>

<style lang="less">
  .my-post{
    .el-tabs__header.is-top,
    >.published,>.record{
      border-radius: 6px;
      border: 1px solid #F1F1F1;
    }
  }
</style>

<!--
 * @Descripttion: 我的勋章弹窗
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-06-26 17:45:57
 * @LastEditors: tangjz
 * @LastEditTime: 2023-07-14 15:35:06
-->
<template>
  <div class="medal-container">
    <!-- 勋章弹窗 -->
    <el-dialog v-model="medalVisible" width="596px">
      <UserAvatar
      :user="store.state.userInfo.userInfos"
      size="57"
      fontSize="22"
      medalSize="80"
      :medalHref="medalHref"
      :isPreview="true"></UserAvatar>
      <p class="medal-desc">{{ medalDesc }}</p>
      <el-tabs
        v-model="is_haved"
        tab-position="top"
        class="demo-tabs"
        v-loading="isLoading">
        <el-tab-pane label="已获得" name="0">
          <el-scrollbar max-height="310px">
            <el-radio-group v-model="radio1" class="temp-radio">
              <el-radio :label="item.id" v-for="item in havedList" :key="item.id"><img :src="item.image_url" @click="getMedalMsg(item)"><span class="medal-title">{{item.name}}</span></el-radio>
            </el-radio-group>
          </el-scrollbar>
          <div v-show="radio1">
            <el-button type="primary" class="confirm-medal" @click="getWearMedal">{{medalText}}</el-button>
          </div>
          <div v-show="!havedList.length" class="medal-empty">这里什么都没有~</div>
        </el-tab-pane>
        <el-tab-pane label="已失效" name="1">
          <el-scrollbar max-height="310px">
            <el-radio-group v-model="radio2" class="temp-radio" disabled>
              <el-radio :label="item.id" v-for="item in expiredList" :key="item.id"><img :src="item.image_url" class="expired-img"><span class="medal-title">{{item.name}}</span></el-radio>
            </el-radio-group>
          </el-scrollbar>
          <div v-show="!expiredList.length" class="medal-empty">这里什么都没有~</div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { dialogMsg } from '@/utils/dialogMsg.js'
import { getMedalList, getMedalWear, getMedalInfo } from '@/apis/userCenter.js'
export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const state = reactive({
      medalVisible: false,
      radio1: undefined,
      radio2: undefined,
      is_haved: '0',
      havedList: [],
      expiredList: [],
      medalDesc: '',
      medalList: {},
      medalHref: '',
      isLoading: false,
      medalText: '立即佩戴'
    })
    watch(() => [state.havedList, state.is_haved], () => {
      state.havedList.map(item => {
        if (Number(item.is_medal) === 1) {
          state.radio1 = item.id
          state.medalList = item
          state.medalDesc = item.description
          state.medalText = '取消佩戴'
        }
      })
    })
    watch(() => state.medalVisible, (newValue) => {
      if (!newValue && route.query.medal_open) {
        const path = route.path
        const id = route.query.id
        router.replace({ path, query: { id } })
      }
    })

    // 获取勋章数据
    const getMedal = () => {
      state.medalVisible = true
      state.isLoading = true
      getMedalList().then(res => {
        if (res.code === 0) {
          state.havedList = res.data.haved
          state.expiredList = res.data.expired
          state.isLoading = false
        } else {
          dialogMsg('error', res.msg)
          state.isLoading = false
        }
      }).catch((err) => {
        console.log(err)
      })
    }

    const getMedalMsg = (item) => {
      state.medalList = item
      state.medalHref = item.image_url
      state.medalDesc = item.description
      Number(item.is_medal) === 1 ? state.medalText = '取消佩戴' : state.medalText = '立即佩戴'
    }

    // 佩戴勋章
    const getWearMedal = () => {
      const param = { id: state.medalList.id }
      getMedalWear(param).then(res => {
        if (res.code === 0) {
          state.medalDesc = state.medalList.description
          state.medalVisible = false
          getMedalInfo().then(res => {
            if (res.code === 0) {
              store.state.userInfo.userMedal.image_url = res.data.image_url
              window.location.reload()
            }
          }
          ).catch(err => console.log(err))
        }
      })
    }
    return {
      ...toRefs(state),
      store,
      getMedal,
      getMedalMsg,
      getWearMedal
    }
  }
}

</script>
<style lang="less" scoped>
  // tab样式
  .medal-container{
    .user-avatar{
      margin-right: 0;
    }
    .medal-desc {
      text-align: center;
      margin: 16px 0 25px;
    }
    :deep(.el-tabs){
      .el-tabs__header.is-top{
        padding: 0 !important;
        border: none;
      }
      .el-tabs__nav-wrap::after{
        content: none;
      }
      .el-tabs__active-bar{
        background-color: var(--el-color-primary);
      }

      .el-tabs__item.is-top{
        position: relative;
        font-weight: 400;
        width: 50%;
        padding: 0 118px !important;
        text-align: center;
        height: 40px;
        line-height: 40px;
        &:nth-child(2)::before{
          content: '';
          position: absolute;
          top: 10px;
          left: 100%;
          width: 1px;
          height: 20px;
          background: #E0E0E0;
        }
        &:hover{
          background: transparent;
          color: var(--el-text-color-primary);
        }
        &.is-active{
          color: var(--el-color-primary);
        }
      }
      .el-tabs__content{
        padding: 0 10px;
        margin-top: 25px !important;
        min-height: 225px;
      }
    }
    :deep(.el-radio) {
      --el-radio-input-border: transparent;
      margin: 0 8px;
      .el-radio__inner{
        position: relative;
        width: 114px;
        height: 114px;
        border-radius: 6px;
        box-sizing: content-box;
      }
      .el-radio__inner::after {
        content: '';
        width: 3px;
        height: 7px;
        border: 1px solid #FFFFFF;
        border-left: 0;
        border-top: 0;
        position: absolute;
        top: 101px;
        left: 104px;
        border-radius: 0;
        z-index: 3;
        background: #7972F0;
      }
      &.is-checked .el-radio__inner::before{
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 18px;
        height: 18px;
        background: #7972F0;
        z-index: 2;
        border-radius: 8px 0 4px 0;
      }
      &.is-checked .el-radio__inner::after{
        transform: rotate(45deg) scaleY(1);
      }
      .el-radio__input.is-checked .el-radio__inner{
        background: transparent;
      }
      .el-radio__label{
        display: flex;
        padding-left: 0;
        justify-content: center;
        .medal-title {
          max-width: 110px;
          text-align: center;
          margin: 8px 0 16px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      img {
        width: 114px;
        height: 114px;
        position: absolute;
        top: 1px;
        left: 1px;
        z-index: 1;
        border-radius: 6px;
        background: #F6F9FA;
      }
    }
    .is-checked img {
      border: 1px solid var(--el-color-primary);
    }
    // 单选框样式
    .medal-empty {
      width: 100%;
      height: 202px;
      text-align: center;
      line-height: 150px;
    }
    .confirm-medal {
      padding: 0 50px;
      margin-top: 27px;
      transform: translateX(120%);
    }
    .expired-img {
      filter: grayscale(100%);
    }
  }

</style>

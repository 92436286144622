<!--
 * @Descripttion: 帖子列表
 * @version: 1.0.0
 * @Author: heqi
 * @Date: 2023-03-28 14:23:08
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-05 10:44:46
-->
<template>
  <div :class="['posts', isLoading ? 'no-top-border' : '']" v-loading="isLoading" v-if="!isNetWorkErr">
    <ul
      v-infinite-scroll="loadMoreData"
      infinite-scroll-immediate="false"
      infinite-scroll-distance="0"
    >
      <li v-for="item in postList" :key="item.id">
        <div :class="['top', type === 'record' ? 'record' : '']">
          <div>
            <svg v-if="type === 'record'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <mask id="path-1-inside-1_663_4779" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.58579 3.41421L3 3L4 2L5 3L5.41421 3.41421L7 5H9L10.5858 3.41421L11 3L12 2L13 3L13.4142 3.41421L15 5V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V5L2.58579 3.41421Z"/>
              </mask>
              <path d="M3 3L1.58579 1.58579L1.58579 1.58579L3 3ZM2.58579 3.41421L4 4.82843L4 4.82843L2.58579 3.41421ZM4 2L5.41421 0.585786L4 -0.828427L2.58579 0.585786L4 2ZM7 5L5.58579 6.41421L6.17157 7H7V5ZM9 5V7H9.82843L10.4142 6.41421L9 5ZM10.5858 3.41421L12 4.82843L12 4.82843L10.5858 3.41421ZM11 3L9.58579 1.58579L9.58579 1.58579L11 3ZM12 2L13.4142 0.585786L12 -0.828427L10.5858 0.585786L12 2ZM13 3L14.4142 1.58579L14.4142 1.58579L13 3ZM13.4142 3.41421L12 4.82843L12 4.82843L13.4142 3.41421ZM15 5H17V4.17157L16.4142 3.58579L15 5ZM1 5L-0.414213 3.58579L-1 4.17157V5H1ZM1.58579 1.58579L1.17157 2L4 4.82843L4.41421 4.41421L1.58579 1.58579ZM2.58579 0.585786L1.58579 1.58579L4.41421 4.41421L5.41421 3.41421L2.58579 0.585786ZM6.41421 1.58579L5.41421 0.585786L2.58579 3.41421L3.58579 4.41421L6.41421 1.58579ZM6.82843 2L6.41421 1.58579L3.58579 4.41421L4 4.82843L6.82843 2ZM8.41421 3.58579L6.82843 2L4 4.82843L5.58579 6.41421L8.41421 3.58579ZM9 3H7V7H9V3ZM10.4142 6.41421L12 4.82843L9.17157 2L7.58579 3.58579L10.4142 6.41421ZM12 4.82843L12.4142 4.41421L9.58579 1.58579L9.17157 2L12 4.82843ZM12.4142 4.41421L13.4142 3.41421L10.5858 0.585786L9.58579 1.58579L12.4142 4.41421ZM10.5858 3.41421L11.5858 4.41421L14.4142 1.58579L13.4142 0.585786L10.5858 3.41421ZM11.5858 4.41421L12 4.82843L14.8284 2L14.4142 1.58579L11.5858 4.41421ZM12 4.82843L13.5858 6.41421L16.4142 3.58579L14.8284 2L12 4.82843ZM17 13V5H13V13H17ZM13 17C15.2091 17 17 15.2091 17 13H13V13V17ZM3 17H13V13H3V17ZM-1 13C-1 15.2091 0.790861 17 3 17V13H3H-1ZM-1 5V13H3V5H-1ZM1.17157 2L-0.414213 3.58579L2.41421 6.41421L4 4.82843L1.17157 2Z" fill="#7972F0" mask="url(#path-1-inside-1_663_4779)"/>
              <rect x="4" y="8" width="2" height="3" rx="1" fill="#7972F0"/>
              <rect x="10" y="8" width="2" height="3" rx="1" fill="#7972F0"/>
              <path d="M16 7V7C16.5523 7 17 7.44772 17 8V15C17 16.1046 16.1046 17 15 17H4C3.44772 17 3 16.5523 3 16V16" stroke="#7972F0" stroke-width="2"/>
            </svg>
            <p
              @click="toArticalDetail(item.id)"
              class="title oneline-overflow"
              v-html="analyzeEmoji(formatHtml(item.title))"
            ></p>
          </div>
          <p v-if="type === 'record'" v-html="item.body && analyzeEmoji(formatHtml(item.body))" class="record-text"> </p>
          <p class="cate" v-if="type !== 'record'">
            {{ item.cate && item.cate.name }}
          </p>
        </div>
        <p class="time">{{formatDate(item.created_at)}}</p>
      </li>
    </ul>
    <empty-data v-if="!isLoading && postList.length === 0"
      size='170'
      image="no-post"
      :description="`还没有${type === 'publish' ? '发布' : type === 'record' ? '回复' : '收藏'}过帖子`"
   ></empty-data>
  </div>
  <template v-else>
    <empty-data image="network-error" description="网络超时，请重试" size="170"></empty-data>
  </template>
</template>

<script setup>
import { onMounted, reactive, toRefs, computed, defineProps } from 'vue'
import { getMyPost, getMyCollect } from '@/apis/userCenter.js'
import { useRouter } from 'vue-router'
import { formatDate, analyzeEmoji } from '@/utils/filterHtml.js'
import { useStore } from 'vuex'
import { forumPostInfo } from '@/apis/forum.js'
import { dialogMsg } from '@/utils/dialogMsg.js'

const props = defineProps({
  type: {
    type: String,
    default: 'publish' // publish-我发布的 record-我回复的 collect-我收藏的
  },
  userId: {
    type: String,
    default: ''
  }
})

const store = useStore()
onMounted(() => {
  init()
})

const router = useRouter()
const state = reactive({
  type: computed(() => props.type),
  postList: [],
  param: {
    limit: 10,
    page: 1,
    type: 1,
    user_id: computed(() => props.userId)
  },
  isLoading: true,
  isNetWorkErr: computed(() => store.state.isNetWorkErr)
})

const { postList, isLoading, isNetWorkErr } = toRefs(state)
// 获取我的帖子
const getPostList = () => {
  getMyPost(state.param).then((res) => {
    dealResponse(res)
  }).catch((err) => {
    console.log(err)
    // dialogMsg('error', err)
  })
}
// 获取收藏的帖子
const getCollectPost = () => {
  getMyCollect(state.param).then((res) => {
    dealResponse(res)
  }).catch((err) => {
    console.log(err)
    // dialogMsg('error', err)
  })
}

function dealResponse (res) {
  state.isLoading = false
  store.commit('setLoadMore', false)
  if (res.code === 0) {
    state.postList.push(...res.data.data)
    if (res.data.data.length < state.param.limit) {
      if ((state.param.page !== 1 && res.data.data.length === 0) || (state.param.page === 1 && res.data.data.length !== 0)) {
        store.commit('setNoMore', true)
      }
    }
  }
}

// 去往详情页
const toArticalDetail = (id) => {
  // 先调用查询是否为已删除
  forumPostInfo(id)
    .then((res) => {
      if (res.code === 0) {
        router.push({
          name: 'articalDetail',
          params: {
            id: id
          }
        })
      } else {
        dialogMsg('error', res.msg)
      }
    })
    .catch((err) => {
      dialogMsg('error', err)
    })
}

const loadMoreData = () => {
  if (state.postList.length !== 0 && !store.state.noMore && !store.state.loadMore) {
    store.commit('setLoadMore', true)
    state.param.page += 1
    getPostList()
  }
}

const init = () => {
  state.postList = []
  store.commit('setLoadMore', false)
  store.commit('setNoMore', false)
  state.param.page = 1
  if (state.type === 'collect') { // 收藏
    state.param.type = 2
    getCollectPost()
  } else {
    state.param.type = state.type === 'publish' ? 2 : 1
    getPostList()
  }
}

</script>

<style lang="less" scoped>
.posts {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #F1F1F1;
  overflow: hidden;
  &.no-top-border{
    border-top:none;
  }
  .el-empty{
    height: 360px;
  }
  li {
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
    padding: 15px 24px;
    font-size: 12px;
    .top{
      display: flex;
      justify-content: space-between;
      color: @default-text-color;
      &.record{
        flex-direction: column;
        >div{
          display: flex;
          padding: 6px 8px;
          background: #F6F9FA;
          border-radius: 6px;
          color: #7972F0;
          line-height: 25px;
          align-items: center;
          svg{
            margin-right: 7px;
          }
        }
      }
    }
    .title{
      font-size: 14px;
      cursor: pointer;
      &:hover{
        color: @active-text-color;
      }
    }
    .record-text{
      color: @default-text-color;
      padding-top: 8px;
      font-size: 14px;
      word-break: break-all;
      word-wrap: break-word;
    }
    .cate{
      color: @second-text-color;
      min-width: 65px;
      margin-left: 20px;
    }
    .time{
      color: @second-text-color;
      margin-top: 8px;
    }
  }
}
</style>

<template>
  <div class="user-message">
    <div class="banner-img" ></div>
    <div class="user-info">
      <div>
        <UserAvatar :user="user" size="80" fontSize="32" :isPreview='true' medalSize="120"></UserAvatar>
        <div class="user-info-right">
          <p class="user-name">{{ user.name }}</p>
          <p class="user-bio">{{ user.bio ? user.bio : '这个人很懒，什么都没有写。' }}</p>
        </div>
      </div>
      <div>
        <template v-if="isSelf">
          <p @click="router.push({ name: 'editPersonal' })" class="setting" >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.644 3.029c-.694-1.326-2.593-1.326-3.287 0a1.855 1.855 0 0 1-2.202.909l-.083-.027c-1.406-.443-2.724.89-2.264 2.29a1.81 1.81 0 0 1-.906 2.182c-1.33.669-1.33 2.566 0 3.234a1.81 1.81 0 0 1 .906 2.181c-.46 1.401.858 2.734 2.264 2.29l.083-.025a1.855 1.855 0 0 1 2.202.908c.694 1.327 2.593 1.327 3.287 0a1.855 1.855 0 0 1 2.201-.908l.084.026c1.406.443 2.723-.89 2.264-2.29a1.81 1.81 0 0 1 .906-2.182c1.329-.668 1.329-2.565 0-3.234a1.81 1.81 0 0 1-.906-2.181c.46-1.4-.858-2.734-2.264-2.29l-.084.026a1.855 1.855 0 0 1-2.201-.91zM10 12.248c1.266 0 2.292-1.006 2.292-2.247 0-1.241-1.026-2.247-2.292-2.247-1.265 0-2.291 1.006-2.291 2.247 0 1.24 1.026 2.247 2.291 2.247z" fill="#999"/></svg>
            修改
          </p>
        </template>
        <div class="panel-data">
          <div v-for="item in cardList" :key="item.key">
            <p>{{ item.key === 'feed_count' ? store.state.userInfo.userFeedCount : panelData[item.key] }}</p>
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getUserInfo } from '@/apis/login.js'
import { getPanelInfo } from '@/apis/userCenter.js'
import { onMounted, reactive, watch, toRefs, defineProps, computed } from 'vue'

const store = useStore()
const router = useRouter()

const props = defineProps({
  userId: {
    type: String,
    default: ''
  },
  isSelf: {
    type: Boolean,
    default: true
  }
})

watch(() => props.userId, () => { init() })

const state = reactive({
  user: {},
  userId: computed(() => props.userId),
  isSelf: computed(() => props.isSelf),
  cardList: [
    { name: '条动态', key: 'feed_count' },
    { name: '条帖子', key: 'post_count' },
    { name: '天前入住', key: 'day' }
  ],
  panelData: {
    feed_count: 0,
    post_count: 0,
    day: 0
  }
})

const { user, isSelf, cardList, panelData } = toRefs(state)
const getUserInf = () => {
  getUserInfo({ user_id: state.userId }).then((res) => {
    if (res.code === 0) {
      state.user = res.data
      // 更新当前登录的用户信息
      if (state.isSelf) {
        store.commit('userInfo/setUserInfo', res.data)
      }
    }
  }).catch((err) => {
    console.log(err)
    // dialogMsg('error', err)
  })
}

const getPanelData = () => {
  getPanelInfo({ user_id: state.userId }).then((res) => {
    if (res.code === 0) {
      state.panelData = res.data
      store.commit('userInfo/setUserFeedCount', res.data.feed_count)
    }
  }).catch((err) => {
    console.log(err)
    // dialogMsg('error', err)
  })
}

const init = () => {
  if (state.userId) {
    getUserInf()
    getPanelData()
  }
}

onMounted(() => {
  init()
})

</script>

<style lang="less">
.user-message {
  background-color: @colorWhite;
  padding-bottom: 17px;
  border: 1px solid #F1F1F1;
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  .banner-img {
    width: 100%;
    height: 132px;
    background: url('../../../../assets/img/userCenter/bg.png') no-repeat;
    background-size: cover;
  }
  .user-info{
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    >div{
      display: flex;
    }
    .user-avatar{
      margin-top: -24px;
    }
    .user-info-right{
      padding-left: 10px;
    }
    .user-name{
      font-size: 16px;
      color: @default-text-color;
      margin: 8px 0;
    }
    .user-bio{
      max-width: 300px;
      word-break: break-word;
      font-size: 12px;
      color: @second-text-color;
    }
  }
  .setting{
    display: flex;
    align-items: center;
    line-height: 16px;
    font-size: 12px;
    margin-right: 8px;
    color: #777;
    cursor: pointer;
    min-width: 50px;
    height: 40px;
    svg{
      margin-right: 5px;
    }
    &:hover{
      color: @active-text-color;
      svg path{
        fill: @active-text-color;
      }
    }
  }
  .panel-data{
    display: flex;
    margin-top: -20px;
    div{
      width: 86px;
      height: 86px;
      padding: 20px 15px;
      background-color: @colorWhite;
      border: 1px solid #F1F1F1;
      box-sizing: border-box;
      border-radius: 6px;
      margin-left: 8px;
      p{
        color: #000;
        font-size: 20px;
        text-align: center;
        &:last-child{
          font-size: 12px;
          color: @second-text-color;
        }
      }
    }
  }
}
</style>

<!--
 * @Description: 我的收藏
 * @Author: heqi
 * @Date: 2022-02-10 11:08:02
 * @LastEditTime: 2024-06-04 18:25:59
 * @LastEditors: liujx@imyfone.cn
 * @FilePath: \mfzj\src\views\layout\personalCenter\component\MyCollect.vue
-->

<template>
  <div class="my-tab">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="my-collect">
      <template v-for="{label, name, isUpdate, type = '', tabComponent} in tabList" :key="name">
        <el-tab-pane :label="label" :name="name">
          <Suspense v-if="isUpdate">
            <template #default>
              <!-- 动态组件 -->
              <component :is="tabComponent" :type="type"/>
            </template>
          </Suspense>
        </el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script setup>
import { computed, reactive, markRaw, toRefs } from 'vue'
import { useStore } from 'vuex'
import MyPostList from './MyPostList.vue'
import MyDynamicList from './MyDynamicList.vue'

const store = useStore()
const state = reactive({
  activeName: 'blog',
  tabList: [
    {
      label: '收藏的动态',
      name: 'blog',
      type: '4',
      isUpdate: computed(() => state.activeName === 'blog'),
      tabComponent: markRaw(MyDynamicList)
    },
    {
      label: '收藏的资讯',
      name: 'news',
      type: '3',
      isUpdate: computed(() => state.activeName === 'news'),
      tabComponent: markRaw(MyDynamicList)
    },
    {
      label: '收藏的帖子',
      name: 'post',
      type: 'collect',
      isUpdate: computed(() => state.activeName === 'post'),
      tabComponent: markRaw(MyPostList)
    },
    {
      label: '收藏的投票',
      name: 'vote',
      type: '9',
      isUpdate: computed(() => state.activeName === 'vote'),
      tabComponent: markRaw(MyDynamicList)
    }
  ]
})

const { activeName, tabList } = toRefs(state)

const handleClick = () => {
  store.commit('setLoadMore', false)
  store.commit('setNoMore', false)
}

</script>
<style lang="less" scoped>
.my-collect {
  .el-tabs__header.is-top {
    margin-bottom: 0 !important;
    border-radius: 6px;
    background: #ffffff;
    border: 1px solid #F1F1F1;
  }
}
</style>

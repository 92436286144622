<!--
 * @Descripttion: 动态列表
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-28 14:23:08
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-08-07 14:11:38
-->

<template>
  <template v-if="!isNetWorkErr">
    <div class="dynamic"
      v-loading="isLoading"
      v-infinite-scroll="loadMoreData"
      infinite-scroll-immediate="false"
      infinite-scroll-distance="0">
      <div>
        <BlogsList
          :type="type === '3' ? 3 : 1"
          :feedList="feedList"
          @collectFeed="update($event, 2)"
          @likeFeed="update($event,1)"
          @deleteFeed="deletes"
          >
        </BlogsList>
      </div>
      <template v-if="!isLoading && feedList.length === 0">
        <empty-data :image="emptyObj.image" :description="`还没有${emptyObj.text}`" size="170"></empty-data>
      </template>
    </div>
  </template>

  <template v-else>
    <empty-data image="network-error" description="网络超时，请重试" size="170"></empty-data>
  </template>
</template>

<script setup>
import { getMyFeed, getMyCollect, getMyVote } from '@/apis/userCenter.js'
import { computed, onMounted, reactive, toRefs, watch, defineProps } from 'vue'
import { updateFeedInfo } from '@/utils/userCenter.js'
import { useStore } from 'vuex'
import BlogsList from '@/components/blog/BlogsList.vue'

const props = defineProps({
  userId: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: '1' // 0-草稿 1-动态 2-定时动态 3-资讯 4-收藏动态 9-收藏投票 91-参与的投票 92-创建的投票
  }
})
const store = useStore()

onMounted(() => {
  getData()
})

watch(() => props.userId, (newValue) => {
  state.param = {
    limit: 10,
    page: 1,
    user_id: newValue
  }
  state.feedList = []
  getData()
})

const descriptionList = [
  {
    type: '0',
    text: '保存过草稿',
    image: 'no-content'
  },
  {
    type: '1',
    text: '写过动态',
    image: 'no-content'
  },
  {
    type: '2',
    text: '保存过定时动态',
    image: 'no-content'
  },
  {
    type: '3', // 接口是1
    text: '收藏过资讯',
    image: 'no-news'
  },
  {
    type: '4', // 接口是1
    text: '收藏过动态',
    image: 'no-content'
  },
  {
    type: '9',
    text: '收藏过投票',
    image: 'no-vote'
  },
  {
    type: '91',
    text: '参与过投票',
    image: 'no-vote'
  },
  {
    type: '92',
    text: '创建过投票',
    image: 'no-vote'
  }
]

const state = reactive({
  param: {
    limit: 10,
    page: 1,
    user_id: props.userId
  },
  isLoading: true,
  feedList: [],
  isNetWorkErr: computed(() => store.state.isNetWorkErr),
  emptyObj: computed(() => descriptionList.filter(item => item.type === props.type)[0] || descriptionList[0])
})

const { isLoading, feedList, isNetWorkErr, emptyObj } = toRefs(state)

function getData () {
  state.isLoading = true
  if (props.type === '0' || props.type === '1' || props.type === '2') {
    getMyFeedList()
  } else if (props.type === '91' || props.type === '92') {
    getVoteList()
  } else {
    getCollectInfoList()
  }
}

const getMyFeedList = () => {
  state.param.publish_status = computed(() => props.type)
  getMyFeed(state.param).then(res => {
    state.isLoading = false
    store.commit('setLoadMore', false)
    if (res.code === 0) {
      dealResponse(res.data.data)
    }
  })
}

const getCollectInfoList = () => {
  state.param.type = props.type === '4' ? 1 : props.type
  getMyCollect(state.param).then(res => {
    state.isLoading = false
    store.commit('setLoadMore', false)
    if (res.code === 0) {
      dealResponse(res.data.data)
    }
  })
}
// 获取我的投票
const getVoteList = () => {
  state.param.type = props.type === '91' ? 1 : 2
  getMyVote(state.param).then(res => {
    state.isLoading = false
    store.commit('setLoadMore', false)
    if (res.code === 0) {
      dealResponse(res.data.data)
    }
  })
}

const dealResponse = (data) => {
  if (data.length < state.param.limit) {
    if ((state.param.page !== 1 && data.length === 0) || (state.param.page === 1 && data.length !== 0)) {
      store.commit('setNoMore', true)
    }
  }
  state.feedList.push(...data)
}
const loadMoreData = () => {
  if (state.feedList.length !== 0 && !store.state.noMore && !store.state.loadMore) {
    store.commit('setLoadMore', true)
    state.param.page += 1
    getData()
  }
}

const update = (id, type) => {
  if (type === 2) { // 收藏
    if (props.type === '3' || props.type === '4' || props.type === '9') { // 已收藏的内容
      state.feedList.splice(state.feedList.findIndex(item => item.id === id), 1)
    } else { // 别的内容
      updateFeedInfo(id, type, state.feedList)
    }
  } else { // 点赞
    updateFeedInfo(id, type, state.feedList)
  }
}

function deletes (id) {
  const index = state.feedList.findIndex((item) => item.id === id)
  state.feedList.splice(index, 1)
  // 删除个人动态，修改个人中心动态数量
  props.type !== '3' && store.commit('userInfo/deleteFeed')
}

</script>
<style lang="less" scoped>
  .dynamic{
    // background-color: #ecf1f7;
    .el-empty{
      height: 360px;
    }
  }
</style>
